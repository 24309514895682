import { Controller } from "stimulus"

class Search {
  constructor(templates) {
    this.templates = templates || {};
  }

  remove_fields(button) {
    button.closest('.fields').remove();
  }

  add_fields(button, type, content) {
    const new_id = new Date().getTime();
    const regexp = new RegExp('new_' + type, 'g');
    button.insertAdjacentHTML('beforebegin', content.replace(regexp, new_id));
  }

  nest_fields(button, type) {
    const content = button.dataset.content;
    this.add_fields(button, type, content);
  }
}

export default class extends Controller {
  connect() {
    this.search = new Search(this.data.get("grouping"));
  }

  addFields(event) {
    this.search.add_fields(event.target, event.target.dataset.fieldType, event.target.dataset.content);
    event.preventDefault();
  }

  removeFields(event) {
    this.search.remove_fields(event.target);
    event.preventDefault();
  }

  nestFields(event) {
    this.search.nest_fields(event.target, event.target.dataset.fieldType);
    event.preventDefault();
  }
}

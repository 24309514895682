import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["form"]

  initialize() {

  }

  connect() {
    this.element.addEventListener('change', () => {
      this.element.requestSubmit();
    });
  }
}

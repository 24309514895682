import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick() {
    const modalController = this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller="modal"]'), 'modal');
    modalController.toggle();
  }
}

import { Controller } from "@hotwired/stimulus"
import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static values = {
    latitude: Number,
    longitude: Number
  };

  connect() {
    mapboxgl.accessToken = "pk.eyJ1Ijoiam9zaG1sIiwiYSI6ImNsZnFyZjF2YzAxOXQzcW5wZzB6anJhdm0ifQ.IUtg6wH_HWW8Gt_kg-Xm_w";
    const latitude = parseFloat(this.latitudeValue);
    const longitude = parseFloat(this.longitudeValue);

    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [longitude, latitude],
      zoom: 14
    });

    // set color of marker
    new mapboxgl.Marker({ color: "000000" })

      .setLngLat([this.longitudeValue, this.latitudeValue])
      .addTo(map);
  }
}

import { Controller } from "@hotwired/stimulus"
import { useTransition, useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['modal', 'content']

  connect() {
    useClickOutside(this, {
      element: this.contentTarget
    })
    useTransition(this, {
      element: this.modalTarget
    })
  }

  toggle() {
    this.toggleTransition()
    document.body.classList.toggle('overflow-hidden')
  }

  hide(event) {
    if (!this.element.contains(event.target) && !this.modalTarget.classList.contains('hidden')) {
      this.close()
    }
  }

  clickOutside(event) {
    event.preventDefault()
    this.toggle()
  }
}

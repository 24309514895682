import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["header", "content"];

  toggle(event) {
    const clickedHeader = event.currentTarget;
    const relatedContent = clickedHeader.nextElementSibling;
    const relatedOpen = clickedHeader.querySelector(".open");
    const relatedClose = clickedHeader.querySelector(".close");

    relatedOpen.classList.toggle("hidden");
    relatedClose.classList.toggle("hidden");
    relatedContent.classList.toggle("hidden");
  }
}
